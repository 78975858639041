@import "./styles/bootstrap";
@import "./styles/list";
@import "./styles/tailwind.css"; // Import Tailwind CSS

body {
  background: #fffdfb;
  letter-spacing: 0.5px;
}

hr {
  height: 1px;
  border-top: 1px solid $color-outline-light;
}

.av-logo-animated {
  path {
    transition: fill 0.25s ease-in-out;
  }
}

@media screen and (min-width: 751px) {
  .customScrollbar {
    overflow: auto;
    scrollbar-gutter: stable; /* Prevent layout shift */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .customScrollbar::-webkit-scrollbar {
    background: transparent;
    width: 0;
    height: 0;
  }

  .customScrollbar::-webkit-scrollbar-thumb {
    height: 50px;
    border-radius: 8px;
    border: 4px solid transparent;
    background-clip: content-box;
    background-color: hsla(0, 0%, 100%, 0.239);
  }

  .customScrollbar::-webkit-scrollbar-thumb:hover {
    background-color: hsla(0, 0%, 86%, 0.239);
  }

  .customScrollbar::-webkit-scrollbar-thumb:active {
    background-color: hsla(0, 0%, 71%, 0.239);
  }
}

.customScrollbar2 {
  &::-webkit-scrollbar {
    background: transparent;
    width: 16px;
  }
  &::-webkit-scrollbar-thumb {
    height: 50px;
    border-radius: 8px;
    border: 4px solid transparent;
    background-clip: content-box;
    background-color: #D9D9D9;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: #b3b3b3;
  }
  &::-webkit-scrollbar-thumb:active {
    background-color: #c8c6c6;
  }
}