@import "variables";

$list-check-icon: url('data:image/svg+xml,<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.06659 9.19967L5.61659 7.74967C5.49436 7.62745 5.34436 7.56634 5.16658 7.56634C4.98881 7.56634 4.83325 7.63301 4.69992 7.76634C4.5777 7.88856 4.51659 8.04412 4.51659 8.23301C4.51659 8.4219 4.5777 8.57745 4.69992 8.69967L6.59992 10.5997C6.72214 10.7219 6.8777 10.783 7.06659 10.783C7.25547 10.783 7.41103 10.7219 7.53325 10.5997L11.3166 6.81634C11.4388 6.69412 11.4999 6.54412 11.4999 6.36634C11.4999 6.18856 11.4333 6.03301 11.2999 5.89967C11.1777 5.77745 11.0221 5.71634 10.8333 5.71634C10.6444 5.71634 10.4888 5.77745 10.3666 5.89967L7.06659 9.19967ZM7.99992 14.6663C7.0777 14.6663 6.21103 14.4912 5.39992 14.141C4.58881 13.7912 3.88325 13.3163 3.28325 12.7163C2.68325 12.1163 2.20836 11.4108 1.85859 10.5997C1.50836 9.78856 1.33325 8.9219 1.33325 7.99967C1.33325 7.07745 1.50836 6.21079 1.85859 5.39967C2.20836 4.58856 2.68325 3.88301 3.28325 3.28301C3.88325 2.68301 4.58881 2.2079 5.39992 1.85767C6.21103 1.5079 7.0777 1.33301 7.99992 1.33301C8.92214 1.33301 9.78881 1.5079 10.5999 1.85767C11.411 2.2079 12.1166 2.68301 12.7166 3.28301C13.3166 3.88301 13.7915 4.58856 14.1413 5.39967C14.4915 6.21079 14.6666 7.07745 14.6666 7.99967C14.6666 8.9219 14.4915 9.78856 14.1413 10.5997C13.7915 11.4108 13.3166 12.1163 12.7166 12.7163C12.1166 13.3163 11.411 13.7912 10.5999 14.141C9.78881 14.4912 8.92214 14.6663 7.99992 14.6663Z" fill="#{$color-primary}"/></svg>');
$list-check-icon-inverted: url('data:image/svg+xml,<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.06659 9.19967L5.61659 7.74967C5.49436 7.62745 5.34436 7.56634 5.16658 7.56634C4.98881 7.56634 4.83325 7.63301 4.69992 7.76634C4.5777 7.88856 4.51659 8.04412 4.51659 8.23301C4.51659 8.4219 4.5777 8.57745 4.69992 8.69967L6.59992 10.5997C6.72214 10.7219 6.8777 10.783 7.06659 10.783C7.25547 10.783 7.41103 10.7219 7.53325 10.5997L11.3166 6.81634C11.4388 6.69412 11.4999 6.54412 11.4999 6.36634C11.4999 6.18856 11.4333 6.03301 11.2999 5.89967C11.1777 5.77745 11.0221 5.71634 10.8333 5.71634C10.6444 5.71634 10.4888 5.77745 10.3666 5.89967L7.06659 9.19967ZM7.99992 14.6663C7.0777 14.6663 6.21103 14.4912 5.39992 14.141C4.58881 13.7912 3.88325 13.3163 3.28325 12.7163C2.68325 12.1163 2.20836 11.4108 1.85859 10.5997C1.50836 9.78856 1.33325 8.9219 1.33325 7.99967C1.33325 7.07745 1.50836 6.21079 1.85859 5.39967C2.20836 4.58856 2.68325 3.88301 3.28325 3.28301C3.88325 2.68301 4.58881 2.2079 5.39992 1.85767C6.21103 1.5079 7.0777 1.33301 7.99992 1.33301C8.92214 1.33301 9.78881 1.5079 10.5999 1.85767C11.411 2.2079 12.1166 2.68301 12.7166 3.28301C13.3166 3.88301 13.7915 4.58856 14.1413 5.39967C14.4915 6.21079 14.6666 7.07745 14.6666 7.99967C14.6666 8.9219 14.4915 9.78856 14.1413 10.5997C13.7915 11.4108 13.3166 12.1163 12.7166 12.7163C12.1166 13.3163 11.411 13.7912 10.5999 14.141C9.78881 14.4912 8.92214 14.6663 7.99992 14.6663Z" fill="#{$white}"/></svg>');

.#{$prefix}list {
  margin: 0;
  padding: 0;

  &--small {
    font-size: $font-size-sm;
    .#{$prefix}list__item {
      padding-left: 1.75rem;
      &:before {
        width: 16px;
        height: 16px;
        top: 3px;
      }
    }
  }

  &__item {
    list-style-type: none;
    padding: 0 0 0 2.25rem;
    margin: 0 0 1rem;
    position: relative;
    font-size: inherit;
    line-height: inherit;

    &--title {
      font-size: 1.125em;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &:before {
      content: '';
      position: absolute;
      width: 24px;
      height: 24px;
      top: 0;
      left: 0;
      background-image: escape-svg($list-check-icon);
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }

    &--no-style {
      padding: 0;

      &:before {
        display: none;
      }
    }
  }

  &--inverted {
    .#{$prefix}list__item {
      &:before {
        background-image: escape-svg($list-check-icon-inverted);
      }
    }
  }

  &--enumerate {
    .#{$prefix}list__item {
      &:before {
        background: none;
        content: attr(data-number);
        width: auto;
        height: auto;
        font-weight: $font-weight-bolder;
        font-size: 1.5rem;
        line-height: 1.4;
        color: $color-primary;
      }
    }
  }

  &--with-icon {
    .#{$prefix}list {
      &__item:before {
        display: none;
      }

      &__icon {
        position: absolute;
        width: 28px;
        height: auto;
        top: 0;
        left: 0;
      }
    }
  }
}
