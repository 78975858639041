@tailwind base;
@tailwind components;
@tailwind utilities;

@media screen and (min-width: 751px) {
  .customScrollbar {
    overflow: auto;
    scrollbar-gutter: stable; /* Prevent layout shift */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .customScrollbar::-webkit-scrollbar {
    background: transparent;
    width: 0;
    height: 0;
  }

  .customScrollbar::-webkit-scrollbar-thumb {
    height: 50px;
    border-radius: 8px;
    border: 4px solid transparent;
    background-clip: content-box;
    background-color: hsla(0, 0%, 100%, 0.239);
  }

  .customScrollbar::-webkit-scrollbar-thumb:hover {
    background-color: hsla(0, 0%, 86%, 0.239);
  }

  .customScrollbar::-webkit-scrollbar-thumb:active {
    background-color: hsla(0, 0%, 71%, 0.239);
  }
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 0 0% 3.9%;
    --card: 0 0% 100%;
    --card-foreground: 0 0% 3.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 0 0% 3.9%;
    --primary: 0 0% 9%;
    --primary-foreground: 0, 0%, 0%;
    --secondary: 0 0% 96.1%;
    --secondary-foreground: 0, 0%, 0%;
    --muted: 0 0% 85%;
    --muted-foreground: 0, 0%, 71%;
    --accent: 0 0% 96.1%;
    --accent-foreground: 0 0% 9%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;
    --border: 0 0% 89.8%;
    --input: 0 0% 89.8%;
    --ring: 0 0% 3.9%;
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
    --radius: 0.5rem;
  }
  .dark {
    --background: 0 0% 3.9%;
    --foreground: 0 0% 98%;
    --card: 0 0% 3.9%;
    --card-foreground: 0 0% 98%;
    --popover: 0 0% 3.9%;
    --popover-foreground: 0 0% 98%;
    --primary: 0 0% 98%;
    --primary-foreground: 0 0% 9%;
    --secondary: 0 0% 14.9%;
    --secondary-foreground: 0 0% 98%;
    --muted: 0 0% 14.9%;
    --muted-foreground: 0 0% 63.9%;
    --accent: #ff9356;
    --accent-foreground: 0 0% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;
    --border: 0 0% 14.9%;
    --input: 0 0% 14.9%;
    --ring: 0 0% 83.1%;
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}
