@import "../variables";

$color-contrast-dark: $text-primary;
$color-contrast-light: $white;
$primary: $color-primary;
$secondary: $color-secondary;
$light: $color-contrast-light;
$dark: $color-contrast-dark;
$success: $color-success;
$warning: $color-warning;
$danger: $color-error;
$info: $color-info;
$warning: $color-warning;

$spacer: 1rem;

$font-family-sans-serif: "Cera Pro", Roboto, sans-serif;
$font-family-monospace: "Cera Pro", Roboto, monospace;

$grid-columns: 24;
$grid-gutter-width: 1rem;

$headings-font-family: $font-family-sans-serif;

$border-radius: 0.625rem;

$btn-font-family: $font-family-sans-serif;
$btn-border-radius-sm: 0.625rem;
$btn-border-radius: 0.75rem;
$btn-border-radius-lg: 1rem;
$btn-font-weight: $font-weight-semibold;
$btn-padding-y-sm: 0.585rem;
$btn-padding-y: 0.875rem;
$btn-padding-y-lg: 1rem;
$btn-padding-x-sm: 1.5rem;
$btn-padding-x: 2.375rem;
$btn-padding-x-lg: 3.5rem;
$btn-border-width: 0.0625rem;

$input-btn-font-size-sm: $font-size-sm;
$btn-font-weight-sm: $font-weight-normal;

$input-padding-y-sm: 0.5825rem;
$input-padding-y: 0.875rem;
$input-padding-y-lg: 1rem;
$input-padding-x-sm: 0.875rem;
$input-padding-x: 1rem;
$input-padding-x-lg: 1.5rem;
$input-border-color: $color-outline;
$input-btn-focus-color-opacity: 0;

$form-label-color: $text-secondary;
$form-label-font-size: $font-size-sm;
$form-label-line-height: 1.3;
$form-check-label-color: rgba($text-primary, .7);
$form-check-label-font-size: $font-size-sm;
$input-focus-border-color: $primary;

$form-feedback-margin-top: 0.5rem;

$headings-margin-bottom: 0;

$form-check-input-border-radius: 6px;
$form-check-input-border: 1px solid $input-border-color;
$form-check-input-width: 1.375rem;
$form-check-padding-start: $form-check-input-width + 0.5rem;

$enable-validation-icons: false;

$form-feedback-margin-top: 0.25rem;
$form-feedback-invalid-color: $danger;

$form-feedback-icon-invalid-color: $form-feedback-invalid-color;
$form-feedback-icon-invalid: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='#{$form-feedback-icon-invalid-color}'><circle cx='6' cy='6' r='4.5'/><path stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/><circle cx='6' cy='8.2' r='.6' fill='#{$form-feedback-icon-invalid-color}' stroke='none'/></svg>") !default;

$card-spacer-y: $spacer * 0.5;
$card-spacer-x: $spacer * 0.5;
$card-title-spacer-y: $spacer * .75;
$card-border-width: 1px;
$card-border-color: $color-outline-light;
$card-border-radius: 0.75rem;
$card-box-shadow: null;
$card-inner-border-radius: null;
$card-cap-padding-y: 0;
$card-cap-padding-x: 0;
$card-cap-bg: $white;
$card-cap-color: null;
$card-height: null;
$card-color: null;
$card-bg: $white;
$card-img-overlay-padding: $spacer;
$card-group-margin: $grid-gutter-width * .5;

$form-check-input-checked-color: $color-primary;
$form-check-input-checked-bg-color: #FFF3E9;
$form-check-input-checked-border-color: $color-primary;
$form-check-input-checked-bg-image: url('data:image/svg+xml,<svg width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.0747 0.961721L12.0696 0.955913L12.0642 0.950509C11.8616 0.750233 11.5405 0.749831 11.3374 0.949304L4.86464 7.15491L1.68009 4.0939C1.47702 3.89431 1.15586 3.89468 0.95322 4.09499C0.748927 4.29694 0.748927 4.61915 0.95322 4.8211L0.953206 4.82111L0.955225 4.82306L4.1889 7.93129C4.37188 8.11157 4.61968 8.2 4.84671 8.2C5.09409 8.2 5.32327 8.10988 5.50442 7.93138L12.0229 1.68192C12.2496 1.48188 12.2494 1.16319 12.0747 0.961721ZM4.91582 7.20414L4.91529 7.2036C4.91548 7.20377 4.91566 7.20397 4.91582 7.20414Z" fill="#{$form-check-input-checked-color}" stroke="#{$form-check-input-checked-color}" stroke-width="0.4"/></svg>');

$btn-close-color: $text-primary;
$btn-close-bg: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.9999 6.00007L6 18M17.9999 17.9999L6 6" stroke="#{$btn-close-color}" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');

$form-validation-states: (
        "invalid": (
                "color": $form-feedback-invalid-color,
                "icon": $form-feedback-icon-invalid
        )
);

$theme-colors: (
        "primary": $primary,
        "secondary": $text-secondary,
        "light": $light,
        "dark": $dark,
        "warning": $warning,
        "danger": $danger,
        "success": $success,
        "info": $info,
);

$button-theme-colors: (
        "primary": $primary,
        "gray": $text-secondary,
        "light": $light,
        "dark": $dark,
);

$font-sizes: (
        1: $h1-font-size,
        2: $h2-font-size,
        3: $h3-font-size,
        4: $h4-font-size,
        5: $h5-font-size,
        6: $h6-font-size,
        7: $font-size-sm,
        8: $font-size-xs,
);

$tooltip-font-size: $font-size-sm;
$tooltip-max-width: 220px;
$tooltip-color: $white;
$tooltip-bg: #212B36;
$tooltip-border-radius: $border-radius;
$tooltip-opacity: 1;
$tooltip-padding-y: $spacer * 0.25;
$tooltip-padding-x: $spacer * 0.75;

$body-color: $text-primary;

$aspect-ratios: (
        "1x1": 100%,
        "4x3": calc(3 / 4 * 100%),
        "16x9": calc(9 / 16 * 100%),
        "16x10": calc(10 / 16 * 100%),
        "21x9": calc(9 / 21 * 100%)
);

$modal-inner-padding: $spacer * 1.5;
$modal-header-padding-y: $spacer * 1.5;
$modal-header-border-color: none;
$modal-footer-margin-between: $spacer * 1.5;

$modal-sm: 480px;
$modal-md: 640px;
$modal-lg: 1200px;
$modal-xl: 1440px;

$input-disabled-color: $text-secondary;
$input-disabled-bg: #F2F2F2;
$input-disabled-border-color: #DFE2E4;
$form-select-disabled-color: $text-secondary;
$form-select-disabled-bg: #F2F2F2;
$form-select-disabled-border-color: #DFE2E4;

$offcanvas-padding-x: $spacer * 1.5;
$offcanvas-horizontal-width: $spacer * 40 + $offcanvas-padding-x;

$form-select-bg-size: 20px 20px; // In pixels because image dimensions
$form-select-indicator-color: #637381;
$form-select-indicator: url("data:image/svg+xml,<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M7 10L12 14L17 10' stroke='#{$form-select-indicator-color}' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/></svg>");

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 860px,
        lg: 1280px,
        xl: 1460px,
        xxl: 1600px,
        xxxl: 1920px
);

$toast-background-color: $white;
$toast-border-color: $color-outline-light;
$toast-border-radius: $border-radius;

$toast-header-color: $text-primary;
$toast-header-background-color: transparent;
$toast-header-border-color: transparent;

$accordion-padding-y: 1.5rem;
$accordion-padding-x: 2rem;
$accordion-border-width: 1px;
$accordion-bg: $white;
$accordion-border-color: #DFE2E4;
$accordion-border-radius: $border-radius;
$accordion-inner-border-radius: subtract($accordion-border-radius, $accordion-border-width);

$accordion-body-padding-y: $accordion-padding-y;
$accordion-body-padding-x: $accordion-padding-x;

$accordion-button-padding-y: $accordion-padding-y;
$accordion-button-padding-x: $accordion-padding-x;
$accordion-button-color: $text-primary;
$accordion-button-bg: $white;
$accordion-button-active-bg: $white;
$accordion-button-active-color: $text-primary;

$accordion-icon-width: 1rem;
$accordion-icon-color: $color-primary;
$accordion-icon-active-color: $color-primary;

$placeholder-opacity-max: .25;
$placeholder-opacity-min: .1;

$container-max-widths: (
        sm: 540px,
        md: 820px,
        lg: 1140px,
        xl: 1360px,
        xxl: 1440px,
        xxxl: 1640px,
);

// scss-docs-start import-stack
// Configuration
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
