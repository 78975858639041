@use "sass:math";
@import "rewrites";

b,
strong {
  font-weight: $font-weight-bold;
}

.form-control:not(:disabled):not(.is-invalid),
.form-select:not(:disabled):not(.is-invalid) {
  &:hover {
    border-color: $form-select-focus-border-color;
  }
}

.form-control {
  &::placeholder,
  &::-webkit-input-placeholder,
  &:-ms-input-placeholder,
  &::-ms-input-placeholder {
    opacity: 0.4;
  }

  &[type=password] {
    letter-spacing: 2px;
  }

  &[type=date] {
    max-height: #{$input-padding-y * 2 + 1.625rem};
  }
}

.form-control-with-icon {
  position: relative;

  &__icon {
    position: absolute;
    top: 1rem;
    left: 1rem;
    z-index: 1;

    &--right {
      left: auto;
      right: 1rem;
      cursor: pointer;
    }
  }

  .form-control {
    padding-left: 3rem;
  }

  &--two-side {
    .form-control {
      padding-right: 3rem;
    }
  }
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  color: $danger;

  &::placeholder,
  &::-webkit-input-placeholder,
  &:-ms-input-placeholder,
  &::-ms-input-placeholder {
    color: $danger;
  }
}

.was-validated .form-check-input:invalid ~ .form-check-label,
.form-check-input.is-invalid ~ .form-check-label {
  color: $form-check-label-color;
}

.text-inherit, .link-inherit {
  font-size: inherit;
  color: inherit;
}

.form-group {
  margin-bottom: $spacer * 1.75;

  &:has(.form-control-error) {
    position: relative;
  }
}

.button-form-group {
  padding-top: $spacer;
}

.form-error-list {
  margin-bottom: $spacer;

  .form-control-error {
    margin-bottom: math.div($spacer, 2);

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.form-label {
  line-height: $form-label-line-height;
}

.form-check-label {
  font-size: $form-check-label-font-size;
  line-height: $form-label-line-height;
}

.form-check-input {
  cursor: pointer;

  &:checked[type=checkbox] {
    background-size: 13px auto;
  }
}

.form-control-error {
  font-size: 0.75rem;
  line-height: 1.25;

  &__icon {
    margin-right: 0.375rem;
    width: 1rem;
    height: auto;
    align-self: start;
  }

  &--with-icon {
    display: flex;
    align-items: center;
  }

  &--absolute {
    position: absolute;
    max-width: 100%;
    top: 100%;
  }
}

.input-group-text {
  background: #FAFAFA;
  font-weight: $font-weight-semibold;
}

.link-primary {
  text-decoration: none;
}

.btn-icon {
  display: inline-flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;

  &:not(.btn-icon--right) > *:first-child {
    margin-right: 0.5rem;
  }

  &.btn-icon--right > *:first-child {
    margin-left: 0.5rem;
  }

  &.btn-icon--only {
    padding-left: $btn-padding-y;
    padding-right: $btn-padding-y;

    & > *:first-child {
      margin: 0 !important;
    }
  }
}

.btn-social-login {
  border: 1px solid $color-outline;
  font-size: $font-size-base * 0.75;
  padding: 1.0625rem 1rem;
  width: 100%;

  &:active,
  &:hover {
    border-color: $color-primary;
    color: $text-primary !important;
  }
}

.btn-sm {
  font-weight: $btn-font-weight-sm;
}

.btn-chip {
  @include button-variant(
                  $btn-chip-bg,
                  $color-outline-light,
                  $text-secondary,
          $hover-background: $color-secondary,
          $hover-border: $color-primary,
          $hover-color: $color-primary,
          $active-background: $color-secondary,
          $active-border: $color-primary,
          $active-color: $color-primary,
  );

  &.btn-sm {
    font-weight: $font-weight-semibold;
  }
}

.cursor-pointer {
  cursor: pointer;
}

/*
  <div class="react-select__control">
  <div class="react-select__value-container">...</div>
  <div class="react-select__indicators">...</div>
  </div>
  <div class="react-select__menu">
  <div class="react-select__menu-list">
  <div class="react-select__option">...</div>
  </div>
 */
.custom-react-select {
  display: flex;

  .react-select {
    &__control {
      box-shadow: none;
      width: 100%;
      padding: $form-select-padding-y $form-select-padding-x;
      -moz-padding-start: subtract($form-select-padding-x, 3px); // See https://github.com/twbs/bootstrap/issues/32636
      font-family: $form-select-font-family;
      @include font-size($form-select-font-size);
      font-weight: $form-select-font-weight;
      line-height: $form-select-line-height;
      color: $form-select-color;
      background-color: $form-select-bg;
      border: $form-select-border-width solid $form-select-border-color !important;
      @include border-radius($form-select-border-radius, 0);
      @include box-shadow($form-select-box-shadow);
      @include transition($form-select-transition);
      appearance: none;
      outline: none !important;

      &:hover {
        border-color: $form-select-focus-border-color !important;
      }

      &:focus {
        border-color: $form-select-focus-border-color !important;
        outline: 0;
        box-shadow: none;
      }
    }

    &__value-container {
      padding: 0;
    }

    &__placeholder {
      padding: 0;
      margin: 0;
      opacity: 0.4;
      color: $input-placeholder-color;
      white-space: nowrap;
    }

    &__input-container {
      padding: 0;
      margin: 0;
    }

    &__dropdown-indicator {
      background-image: escape-svg($form-select-indicator);
      background-repeat: no-repeat;
      background-position: center;
      background-size: $form-select-bg-size;

      svg {
        display: none;
      }
    }

    &__clear-indicator {
      background-image: escape-svg($btn-close-bg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 16px;

      svg {
        display: none;
      }
    }

    &__indicator-separator {
      display: none;
    }

    &__menu {
      overflow: hidden;
      border-radius: $border-radius;
      z-index: 10;
      background: $white;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 15px;
    }

    &__option {
      cursor: pointer;
      font-size: $font-size-sm;
      transition: $transition-base;

      &:hover {
        background: $color-secondary;
        color: $text-primary;
      }

      &--is-selected {
        background: $color-primary;
        color: $white;
      }
    }

    &__multi-value {
      margin: 0 2px 0 0;
      background: $color-secondary;
      border-radius: 4px;
      font-size: $font-size-xs;

      &__remove {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;

        &:hover {
          color: $white;
          background: $danger;
        }
      }
    }
  }
}

.#{$prefix}financial-tabs {
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: nowrap;
  @include media-breakpoint-only(xs) {
    flex-wrap: wrap;
  }

  .nav-item {
    padding: 0;
    margin: 0;
    flex: 1 1 auto;
    @include media-breakpoint-only(xs) {
      flex: 0 1 100%;
    }
  }

  .nav-link {
    background: rgba(248, 250, 251, 0.7);
    text-transform: uppercase;
    font-weight: $font-weight-semibold;
    color: #7C828F;
    padding: $spacer * 1.25;
    border: none;
    border-bottom: 2px solid #F1F2F4;
    border-radius: 0;
    width: 100%;

    &.active {
      color: $color-primary;
      border-color: $color-primary;
      background: $color-secondary;
    }
  }
}

.btn-transparent {
  &:checked,
  &:active,
  &.active,
  &.show,
  &:focus {
    border-color: transparent !important;
  }
}

.#{$prefix}financial-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  margin-bottom: 0.75rem;
  scrollbar-width: thin;

  td, th {
    padding: $spacer * 1.5;
    text-align: left;
    border-bottom: 1px solid $color-outline-light;
  }

  .sticky-cell {
    position: sticky;
    left: 0;
    background-color: #ffffff;
    white-space: nowrap;
    @include media-breakpoint-down(md) {
      white-space: initial;
    }
    @include media-breakpoint-only(xs) {
      position: static;
    }

    &--right {
      left: auto;
      right: 0;
    }
  }

  tbody {
    td:first-child, td:last-child {
      border-bottom-color: transparent;
    }

    tr:last-child {
      th, td {
        border-bottom: none;
      }
    }
  }

  tfoot, .tfoot {
    th, td {
      border-bottom: none;
      background: #FAFBFC !important;
    }
  }

  tbody, tfoot {
    th {
      font-weight: $font-weight-normal;
    }

    td {
      font-weight: $font-weight-semibold;
    }

    th, td {
      font-size: 1.125rem;
    }
  }

  @include media-breakpoint-only(xs) {
    display: grid;
    grid-template-columns: initial;
    grid-column-gap: 0;

    thead,
    tbody,
    tfoot,
    tr {
      display: contents;
    }

    td:empty,
    thead th {
      display: none;
    }

    td:not([title]) {
      text-align: center;
    }
    td[title] {
      padding: 0.5rem 1rem;

      &:before {
        content: attr(title);
        display: block;
        opacity: 0.8;
        font-style: italic;
        font-weight: $font-weight-semibold;
        color: $color-primary;
      }
    }
    tfoot td {
      margin: 0 !important;
      border-radius: 0 !important;
    }
  }
}

.dropdown-toggle {
  cursor: pointer;
  display: flex;
  align-items: center;

  &:after {
    width: 20px;
    height: 20px;
    background-image: escape-svg($form-select-indicator);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    border: none;
    transition: $transition-base;
    transform-origin: center;
  }
}

.modal-header {
  text-align: center;
  font-weight: $font-weight-bold;
  font-size: $font-size-lg;
  justify-content: center;
  padding-top: $spacer * 3;
  padding-bottom: $spacer * 2;
}

.modal-content {
  position: relative;

  .btn-close {
    position: absolute;
    top: $spacer * 1.5;
    right: $spacer * 1.5;
  }
}

.modal-footer {
  padding-bottom: $spacer * 3;
  justify-content: center;
  @include media-breakpoint-down(sm) {
    flex-direction: row-reverse;
  }

  .btn {
    min-width: 186px;
  }

  > * {
    margin-top: 0;
    margin-bottom: 0;
    @include media-breakpoint-down(sm) {
      margin-bottom: math.div($spacer, 2);
      &:first-child {
        order: 6;
      }
      &:last-child {
        order: 1;
      }
    }
  }
}

.toast-header {
  position: relative;
  padding-right: 2rem;
  font-size: $font-size-sm;
  line-height: 1.42;
  align-items: flex-start;
  color: $text-primary;

  .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: 0;
    width: 1.5rem;
    height: 1.5rem;
  }
}

.toast-body {
  color: $text-secondary;
}

.av-accordion {
  .accordion-header {
    font-size: 1.125rem !important;
    border: none !important;
  }

  .accordion-button {
    &:after {
      border-radius: 50%;
      box-shadow: -2px 0 2px 1px rgba($black, .075);
      width: 2rem;
      height: 2rem;
      background-position: center;
      transition: $transition-base;
      transform: rotate(-90deg);
    }

    &:not(.collapsed)::after {
      box-shadow: 0 -2px 2px 1px rgba($black, .075);
      transform: rotate(-180deg);
    }
  }

  .accordion-body {
    background: $white;
  }
}

.av-input-group {
  &--no-border {
    &.input-group {
      cursor: text;

      .input-group-text {
        border-right-width: 0;
        padding-right: 0;
        background: transparent;
        border: none !important;
      }

      .form-control {
        border: none !important;
        outline: none !important;
        padding-left: 0.375rem !important;
      }

      background: $input-bg;
      border: $input-border-width solid $input-border-color;
      border-radius: $input-border-radius;

      &:hover,
      &:has(.form-control:focus),
      &:has(.form-control:focus-visible) {
        border-color: $input-focus-border-color;
      }
    }
  }
}

$verification-input-size: 55px;
$verification-input-size-sm: 36px;
$verification-input-gap: 12px;
$verification-input-gap-sm: 8px;

.av-verification-input {
  width: #{$verification-input-size * 6 + $verification-input-gap * 5};
  gap: $verification-input-gap;
  @include media-breakpoint-down(sm) {
    width: #{$verification-input-size-sm * 6 + $verification-input-size-sm * 5};
    gap: $verification-input-size-sm;
  }

  .vi {
    height: $verification-input-size;
  }

  &__character {
    width: $verification-input-size;
    height: $verification-input-size;
    border-radius: $input-border-radius;
    border: 1px solid $color-secondary;
    background: $color-secondary;
    color: $text-primary;
    font-size: $font-size-lg;
    font-weight: $font-weight-bold;
    transition: $transition-base;
    outline: none !important;
    line-height: #{$verification-input-size - 2px};
    position: relative;

    &:empty {
      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background: $text-primary;
        transform-origin: center;
        transform: translate(-50%, -50%);
      }
    }

    @include media-breakpoint-down(sm) {
      width: $verification-input-size-sm;
      height: $verification-input-size-sm;
      font-size: $font-size-base;
      font-weight: $font-weight-semibold;
      line-height: #{$verification-input-size-sm - 2px};
    }

    &--selected {
      border-color: $color-primary;
    }
  }
}

.av-table-resizer {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 5px;
  background: $color-secondary;
  cursor: col-resize;
  user-select: none;
  touch-action: none;

  &--resizing {
    background: $color-primary;
    opacity: 1;
  }
}

.av-table-ellipsis {
  table-layout: fixed;

  &__cell {
    min-width: 34px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:has(.av-table-ellipsis__cell--no-ellipsis) {
      white-space: normal;
      overflow: initial;
    }
  }
}

.table-responsive {
  &--loading {
    position: relative;
  }

  &__loader {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: transparentize($white, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.av-highlight .highlighted {
  display: inline-block;
  background: rgba($color-primary, 0.2);
}
