$text-primary: #151515;
$text-secondary: #6B7280;
$text-muted: #747B88;

$white: #ffffff;
$black: #000000;

$color-primary: #FF5E04;
$color-secondary: #FFF3E9;
$color-outline: #DFE2E4;
$color-outline-light: #E7E7E7;
$color-error: #FF6C6C;
$color-success: #34A853;
$color-info: $color-primary;
$color-warning: #E48515;
$font-size-base: 1rem;

$btn-chip-bg: #FFF9F3;

$font-weight-lighter: 200;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-semibold: 500;
$font-weight-bold: 700;
$font-weight-bolder: 900;

$line-height-base: 1.5;
$line-height-sm: 1.33;
$line-height-lg: 1.67;

$h1-font-size: $font-size-base * 2.25;
$h2-font-size: $font-size-base * 2;
$h3-font-size: $font-size-base * 1.75;
$h4-font-size: $font-size-base * 1.5;
$h5-font-size: $font-size-base * 1.25;
$h6-font-size: $font-size-base;

$font-size-root: 1rem;
$font-size-base: $font-size-base;
$font-size-xs: 0.75rem;
$font-size-sm: 0.875rem;
$font-size-lg: 1.25rem;
$font-size-xl: 1.5rem;

$prefix: "av-";
